import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { remotePost } from 'utils/RemoteRequest';
import { plugApis, getDbCookie } from 'utils/HelperFunctions';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Card from 'components/card';

const AddTask = (props) => {
	// eslint-disable-next-line
  const { isOpen, onOpen, onClose, userId } = props;

	const [isLoading, setIsLoading] = useState(false);
	const [newPlanId, setNewPlanId] = useState('');
	// eslint-disable-next-line
  const [errors, setErrors] = useState("");

	const addTask = (event) => {
		event.preventDefault();
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		setErrors('');

		remotePost(
			plugApis().change_plan,
			JSON.stringify({ user_id: userId, plan_id: newPlanId }),
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + getDbCookie('autoplug-rest'),
			}
		)
			.then(function (response) {
				if (response.success) {
					toast('Task added successfully');
				} else {
					toast(response?.errors?.message?.[0]);
				}
				setIsLoading(false);
			})
			.catch(function () {
				toast('Please try again!');
				setIsLoading(false);
			});
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} className="!z-[997]">
				<ModalOverlay className="!z-[996] bg-[#000] !opacity-30" />
				<ModalContent className="!z-[998] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
					<ModalBody>
						<Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
							<h1 className="mb-4 text-2xl font-bold">
								Add Task
							</h1>
							<p className="mb-2">Enter the task number.</p>
							<div>
								<input
									type="number"
									className="mb-5 mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
									onChange={(e) =>
										setNewPlanId(e.target.value)
									}
									placeholder="Task Number"
								/>
							</div>

							<div className="flex gap-2">
								<button
									className="linear rounded-xl bg-brand-500 px-5 py-2 font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
									onClick={addTask}
								>
									Add Task
								</button>

								<button
									onClick={onClose}
									className="linear rounded-xl border-2 border-red-500 px-5 py-2 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
								>
									Close
								</button>
							</div>
						</Card>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
export default AddTask;
