import { dashboardTableColumns } from './variables/columnsData';
import DashboardTable from 'views/admin/user/components/DashboardTable';
import { remoteGet } from 'utils/RemoteRequest';
import { useEffect, useState } from 'react';
import { plugApis, getDbCookie } from 'utils/HelperFunctions';
import Loading from 'components/progress/Loading';

const UserDashboard = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingInside, setIsLoadingInside] = useState(true);
	const [users, setUsers] = useState(false);
	const [filter, setFilter] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [paginationOn, setPaginationOn] = useState(false);

	useEffect(() => {
		getUsers();
		// eslint-disable-next-line
	}, [filter, currentPage]);

	const getUsers = () => {
		const apiURL = new URL(plugApis().users);
		const searchParams = new URLSearchParams('');
		
		if(filter?.name?.length>=1){
			if(currentPage !== 1){
				if(!paginationOn)
				setCurrentPage(1);
			}
		}
		
		if (filter) {
			setIsLoadingInside(true);			
			searchParams.append('name', filter.name);
		}
		searchParams.append('page_number', currentPage);
		apiURL.search = searchParams;
		
		remoteGet(apiURL, {
			Authorization: 'Bearer ' + getDbCookie('autoplug-rest'),
		}).then(function (response) {
			if (response.success) {
				setTotalPages(response?.data?.total_pages);
				setUsers(response?.data?.users);
				setIsLoading(false);
				setIsLoadingInside(false);
				setPaginationOn(false);
			}		
		});
	};

	return isLoading ? (
		<div className="flex justify-center items-center h-screen w-full">
			<Loading />
		</div>
	) : (
		<div>
			{/* Tables & Charts */}
			<div className="grid grid-cols-1 gap-5 mt-5 xl:grid-cols-1">
				{/* Check Table */}
				
					<div>
						<DashboardTable
							columnsData={dashboardTableColumns}
							tableData={users}
							searchCallback={setFilter}
							totalPages={totalPages}
							currentPage={currentPage}
							isLoading={isLoadingInside}
							onPageChange={(pageNumber) => {
								setIsLoadingInside(true);
								setPaginationOn(true);
								setCurrentPage(pageNumber);
							}}
						/>
					</div>
				
			</div>
		</div>
	);
};

export default UserDashboard;
