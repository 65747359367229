export const columnsDataDevelopment = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'TECH',
		accessor: 'tech',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];

export const dashboardTableColumns = [
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'New Users',
		accessor: 'newUsersCount',
	},
	{
		Header: 'First Workflow',
		accessor: 'firstWorkflowCount',
	},
	{
		Header: 'Today Workflow',
		accessor: 'todayWorkflowCount',
	},
	{
		Header: 'Successful Runs',
		accessor: 'successfulRuns',
	},
	{
		Header: 'Failed Runs',
		accessor: 'failedRuns',
	},
];

export const columnsDataColumns = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];
