import React from 'react';

// Admin Imports
import MainDashboard from 'views/admin/default';
import UserDashboard from 'views/admin/user';

// Auth Imports
import SignIn from 'views/auth/SignIn';

// Icon Imports
import { MdHome, MdPerson, MdLock } from 'react-icons/md';

const routes = [
	{
		name: 'Dashboard',
		layout: '/admin',
		path: 'dashboard',
		icon: <MdHome className="h-6 w-6" />,
		component: <MainDashboard />,
	},
	{
		name: 'Users',
		layout: '/admin',
		path: 'users',
		icon: <MdPerson className="h-6 w-6" />,
		component: <UserDashboard />,
		secondary: true,
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: 'sign-in',
		icon: <MdLock className="h-6 w-6" />,
		component: <SignIn />,
		disabled: true,
	},
];
export default routes;
