import React, { useEffect, useMemo, useState } from "react";
import Card from "components/card";
import { dateFormat } from "utils/HelperFunctions";
import { MdSearch } from "react-icons/md";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useDisclosure } from "@chakra-ui/hooks";
import ChangePlan from "views/admin/user/components/ChangePlan";
import Pagination from "components/Pagination";
import AddTask from "./AddTask";
import Loading from "components/progress/Loading";

const DashboardTable = (props) => {
  const { columnsData, tableData, searchCallback, isLoading } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const taskModal = useDisclosure();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [userId, setUserId] = useState("");
  const [currentPlanId, setCurrentPlanId] = useState("");

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10, pageIndex: 0, pageCount: -1 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const [filter, setFilter] = useState({
    name: "",
  });

  useEffect(() => {
      searchCallback(filter);
    // eslint-disable-next-line
  }, [filter]);

  const handleClear = () => {
    if(filter?.name){
      setFilter({ ...filter, name: "" });
    }
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto"}>
      {props.title && (
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {props.title}
          </div>
        </header>
      )}

      <div className="mt-6 overflow-x-scroll xl:overflow-x-hidden">
        <div className="flex mb-10 ml-6 items-center">
        <div className="relative max-w-[300px] md:w-96">
          <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full">
            <MdSearch className="w-4 h-4 ml-1 dark:text-gray-800" />
          </div>
          <input
          value={filter?.name}
            onChange={(event) => {
              setFilter({ ...filter, name: event.target.value });
            }}
            type="text"
            className="w-full rounded-[16px] bg-[#F4F7FE] py-2 pl-10 pr-4 placeholder-gray-400 focus:border-blue-500 focus:outline-none dark:bg-darkBody dark:text-white dark:placeholder:!text-white"
            placeholder="Search"
          />
         
        </div>
        <button className="ml-2 rounded-[16px] px-4 py-2 focus:z-20 focus:outline-offset-0 active:bg-brand-400  dark:ring-gray-800 dark:hover:bg-brand-300 dark:active:bg-brand-400 bg-gray-200 text-gray-500  dark:bg-darkBody dark:text-gray-700 hover:dark:text-gray-300 hover:bg-brand-600 hover:text-white" onClick={handleClear}>Clear</button>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-screen w-full">
            <Loading />
          </div>
        ) : (
          <table
            {...getTableProps()}
            className="w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pb-[10px] pl-6 pr-16 text-start dark:!border-navy-700"
                      key={index}
                    >
                      <div className="text-sm font-bold tracking-wide text-gray-400 uppercase lg:text-sm">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Date") {
                        data = (
                          <div className="flex items-center gap-2">
                            <p className="font-normal text-navy-700 dark:text-white">
                              {dateFormat("M j, Y", cell.value)}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "Action") {
                        data = (
                          <div className="flex flex-nowrap">
                            <button
                              className="p-2 text-xs font-medium text-white transition duration-200 rounded-lg min-w-max bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-blueSecondary dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                              onClick={() => {
                                setUserId(cell.row.original.ID);
                                setCurrentPlanId(
                                  cell.row.original.current_subscription?.[0]
                                    ?.plan_id ?? 0
                                );
                                onOpen();
                              }}
                            >
                              Change Plan
                            </button>
                            <button
                              className="hidden p-2 ml-2 text-xs font-medium text-white transition duration-200 rounded-lg min-w-max bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-blueSecondary dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                              onClick={() => {
                                setUserId(cell.row.original.ID);
                                taskModal.onOpen();
                              }}
                            >
                              Add Free Task
                            </button>
                          </div>
                        );
                      } else {
                        data = (
                          <div className="flex items-center">
                            <p className="font-normal text-secondary-gray-900 dark:text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="border-b pb-[16px] pl-6 pt-[14px] dark:border-gray-800"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <div className="flex flex-col items-center p-2 md:flex-row-reverse lg:flex-row-reverse">
          <div className="flex justify-center basis-5/6">
            <div className="md:-ml-36">            
            <Pagination
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onPageChange={props.onPageChange}
            />
            </div>
          </div>
          <div className="p-2 text-gray-500 basis-1/6">
            Page {props.currentPage} of {props.totalPages}
          </div>
        </div>
      </div>
      <ChangePlan
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        userId={userId}
        currentPlanId={currentPlanId}
      />
      <AddTask
        isOpen={taskModal.isOpen}
        onOpen={taskModal.onOpen}
        onClose={taskModal.onClose}
        userId={userId}
      />
    </Card>
  );
};

export default DashboardTable;
