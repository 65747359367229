// Custom components
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function InputField(props) {
	const {
		label,
		id,
		extra,
		type,
		required,
		placeholder,
		variant,
		state,
		disabled,
		name,
		onChange,
		value,
	} = props;

	return (
		<div className={`${extra}`}>
			{label && (
				<label
					htmlFor={id}
					className={`text-sm text-navy-700 dark:text-white ${
						variant === 'auth'
							? 'ml-1.5 font-medium'
							: 'ml-3 font-bold'
					}`}
				>
					{label}
				</label>
			)}
			{type === 'daterangepicker' ? (
				<DatePicker
					isClearable={true}
					showIcon
					id={id}
					startDate={props.filter.dateRangeStart}
					endDate={props.filter.dateRangeEnd}
					onChange={props.handleDateRange}
					dateFormat="dd/MM/yy"
					disabledKeyboardNavigation
					selectsRange
					placeholderText={placeholder}
					dropdownMode="select"
					showPopperArrow={false}
					className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl bg-white p-3 text-sm placeholder-navy-900 outline-none dark:border-gray-400 dark:!bg-darkBodyCard dark:text-white dark:placeholder:!text-white`}
				/>
			) : (
				<input
					disabled={disabled}
					type={type}
					id={id}
					placeholder={placeholder}
					name={name}
					className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
						disabled === true
							? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
							: state === 'error'
							? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
							: state === 'success'
							? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
							: 'border-gray-200 dark:!border-white/10 dark:text-white'
					}`}
					onChange={onChange}
					value={value ?? ''}
					required={required ? true : false}
				/>
			)}
		</div>
	);
}

export default InputField;
