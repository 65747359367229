export const columnsDataDevelopment = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'TECH',
		accessor: 'tech',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];

export const dashboardTableColumns = [
	{
		Header: 'Name',
		accessor: 'display_name',
	},
	{
		Header: 'Email',
		accessor: 'user_email',
	},
	{
		Header: 'Current Plan',
		accessor: 'current_subscription[0].plan.name',
	},
	{
		Header: 'Expires On',
		accessor: 'current_subscription[0].expires_on',
	},
	{
		Header: 'Action',
		accessor: 'actions',
	},
];

export const columnsDataColumns = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];
