import React from 'react';
import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import Footer from 'components/footer/Footer';
import routes from 'routes.js';
import { getDbCookie } from 'utils/HelperFunctions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Admin(props) {
	const { ...rest } = props;
	const location = useLocation();
	const [open, setOpen] = React.useState(true);
	const [currentRoute, setCurrentRoute] = React.useState('Main Dashboard');
	const navigate = useNavigate();

	React.useEffect(() => {
		window.addEventListener('resize', () =>
			window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
		);

		if (!getDbCookie('autoplug-rest')) {
			navigate('/auth/sign-in');
		}
		// eslint-disable-next-line
  }, []);
	React.useEffect(() => {
		getActiveRoute(routes);
	}, [location.pathname]);

	const getActiveRoute = (routes) => {
		const activeRoute = 'Main Dashboard';
		for (let i = 0; i < routes.length; i++) {
			if (
				window.location.href.indexOf(
					routes[i].layout + '/' + routes[i].path
				) !== -1
			) {
				setCurrentRoute(routes[i].name);
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		const activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (
				window.location.href.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].secondary;
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return (
					<Route
						path={`/${prop.path}`}
						element={prop.component}
						key={key}
					/>
				);
			}
			return null;
		});
	};

	document.documentElement.dir = 'ltr';
	return getDbCookie('autoplug-rest') ? (
		<div className="flex h-full w-full">
			<Sidebar open={open} onClose={() => setOpen(false)} />
			{/* Navbar & Main Content */}
			<div className="h-full w-full bg-lightPrimary dark:!bg-darkBody">
				{/* Main Content */}
				<main
					className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
				>
					{/* Routes */}
					<div className="h-full">
						<Navbar
							onOpenSidenav={() => setOpen(true)}
							logoText={'SureTriggers'}
							brandText={currentRoute}
							secondary={getActiveNavbar(routes)}
							{...rest}
						/>
						<div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
							<Routes>
								{getRoutes(routes)}

								<Route
									path="/"
									element={
										<Navigate
											to="/admin/dashboard"
											replace
										/>
									}
								/>
							</Routes>
						</div>
						<div className="p-3">
							<Footer />
						</div>
					</div>
				</main>
			</div>
			<ToastContainer />
		</div>
	) : (
		<div>Unauthenticated, Authenticate first!</div>
	);
}
