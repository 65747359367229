import Chart from 'views/admin/default/components/Chart';
import { IoMdHome } from 'react-icons/io';
import { IoDocuments } from 'react-icons/io5';
import { MdBarChart, MdDashboard } from 'react-icons/md';
import { dashboardTableColumns } from './variables/columnsData';
import Widget from 'components/widget/Widget';
import DashboardTable from 'views/admin/default/components/DashboardTable';
import { remoteGet } from 'utils/RemoteRequest';
import { useEffect, useState } from 'react';
import { plugApis, getDbCookie, dateFormat } from 'utils/HelperFunctions';
import InputField from 'components/fields/InputField';
import Loading from 'components/progress/Loading';

const Dashboard = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [dashboardData, setDashboardData] = useState(false);
	const [chartData, setChartData] = useState(false);
	const [tableData, setTableData] = useState(false);
	const [filter, setFilter] = useState({
		dateRangeStart: null,
		dateRangeEnd: null,
	});

	const handleDateRange = (dates = '') => {
		const [dateRangeStart, dateRangeEnd] = dates;
		setFilter({
			...filter,
			dateRangeStart,
			dateRangeEnd,
		});
	};

	const fetchDashboardData = () => {
		const apiURL = new URL(plugApis().dashboard);
		apiURL.search = new URLSearchParams({
			dateRangeStart: filter?.dateRangeStart
				? dateFormat('Y-m-d 00:00:00', filter?.dateRangeStart)
				: '',
			dateRangeEnd: filter?.dateRangeEnd
				? dateFormat('Y-m-d 23:59:59', filter?.dateRangeEnd)
				: '',
		}).toString();

		remoteGet(apiURL, {
			Authorization: 'Bearer ' + getDbCookie('autoplug-rest'),
		}).then(function (response) {
			if (response.success) {
				setDashboardData(response.data);
				setIsLoading(false);
				formatChartData(response.data.admin_analytics);
			}
		});
	};
	useEffect(() => {
		if (
			(filter.dateRangeEnd && filter.dateRangeStart) ||
			!(filter.dateRangeEnd || filter.dateRangeStart)
		) {
			fetchDashboardData();
		}
		// eslint-disable-next-line
  }, [filter]);

	const formatChartData = (data) => {
		const analyticsData = {};
		const tableData = [];

		analyticsData.firstWorkflowCount = [
			{ name: 'First Workflow', data: [] },
		];
		analyticsData.todayWorkflowCount = [
			{ name: 'Today Workflow', data: [] },
		];
		analyticsData.newUsersCount = [{ name: 'New Users', data: [] }];
		analyticsData.successfulRuns = [
			{ name: 'Successful Runs', data: [] },
			{ name: 'Automations', data: [] },
		];
		analyticsData.failedRuns = [
			{ name: 'Failed Runs', data: [] },
			{ name: 'Automations', data: [] },
		];

		data.map((v) => {
			const json = JSON.parse(v.analytics_json);
			const startDate = v.start_date;
			analyticsData.firstWorkflowCount[0].data.push({
				x: startDate,
				y: json.firstWorkflowCount,
			});

			analyticsData.todayWorkflowCount[0].data.push({
				x: startDate,
				y: json.todayWorkflowCount,
			});

			analyticsData.newUsersCount[0].data.push({
				x: startDate,
				y: json.newUsersCount,
			});

			analyticsData.successfulRuns[0].data.push({
				x: startDate,
				y: json.successfulRuns.total_runs,
			});
			analyticsData.successfulRuns[1].data.push({
				x: startDate,
				y: json.successfulRuns.automations,
			});

			analyticsData.failedRuns[0].data.push({
				x: startDate,
				y: json.failedRuns.total_runs,
			});
			analyticsData.failedRuns[1].data.push({
				x: startDate,
				y: json.failedRuns.automations,
			});

			tableData.push({
				date: startDate,
				firstWorkflowCount: json.firstWorkflowCount,
				todayWorkflowCount: json.todayWorkflowCount,
				newUsersCount: json.newUsersCount,
				successfulRuns: json.successfulRuns.total_runs,
				successfulAutomations: json.successfulRuns.automations,
				failedRuns: json.failedRuns.total_runs,
				failedAutomations: json.failedRuns.automations,
			});
			return null; // Returning null or any other value to satisfy the map() method
		});

		setChartData(analyticsData);
		setTableData(tableData);
	};

	return isLoading ? (
		<div className="flex justify-center items-center h-screen w-full">
			<Loading/>
		</div>
	) : (
		<div>
			<div className="flex items-center justify-start w-full h-full px-2 mt-6 mb-4 md:mx-0 md:px-0 lg:mb-6 lg:items-center lg:justify-start">
				<div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0">
					<InputField
						variant="auth"
						extra="w-60"
						placeholder="Select Date Range"
						id="date-range-picker"
						type="daterangepicker"
						name="filter_date_range"
						filter={filter}
						handleDateRange={handleDateRange}
					/>
				</div>
			</div>

			{/* Card widget */}

			<div className="grid grid-cols-1 gap-5 mt-3 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
				<Widget
					icon={<MdBarChart className="h-7 w-7" />}
					title={'Users'}
					subtitle={dashboardData.total_users}
				/>
				<Widget
					icon={<IoDocuments className="w-6 h-6" />}
					title={'Workflows'}
					subtitle={dashboardData.total_automations}
				/>
				<Widget
					icon={<MdBarChart className="h-7 w-7" />}
					title={'Connections'}
					subtitle={dashboardData.total_connections}
				/>
				<Widget
					icon={<MdDashboard className="w-6 h-6" />}
					title={'Total Tasks'}
					subtitle={dashboardData.total_tasks}
				/>
				<Widget
					icon={<MdBarChart className="h-7 w-7" />}
					title={'Successful Actions'}
					subtitle={dashboardData.successful_actions}
				/>
				<Widget
					icon={<IoMdHome className="w-6 h-6" />}
					title={'Failed Actions'}
					subtitle={dashboardData.failed_actions}
				/>
			</div>

			{/* Charts */}

			<div className="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2">
				{chartData && (
					<>
						<Chart
							title="New Users"
							series={chartData.newUsersCount}
							className="col-span-2"
						/>
						<Chart
							title="First Workflow Published"
							series={chartData.firstWorkflowCount}
						/>
						<Chart
							title="All Workflow Published Today"
							series={chartData.todayWorkflowCount}
						/>
						<Chart
							title="Successful Runs/Automations"
							series={chartData.successfulRuns}
						/>
						<Chart
							title="Failed Runs/Automations"
							series={chartData.failedRuns}
						/>
					</>
				)}
			</div>

			{/* Tables & Charts */}

			<div className="grid grid-cols-1 gap-5 mt-5 xl:grid-cols-1">
				{/* Check Table */}
				<div>
					{tableData && (
						<DashboardTable
							columnsData={dashboardTableColumns}
							tableData={tableData}
							title="Dashboard Data"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
