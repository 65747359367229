import InputField from 'components/fields/InputField';
import { remotePost } from 'utils/RemoteRequest';
import { useState } from 'react';
import { plugApis } from 'utils/HelperFunctions';
import { useNavigate } from 'react-router-dom';

export default function SignIn() {
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState('');
	const navigate = useNavigate();

	const handleLogin = (event) => {
		event.preventDefault();
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		setErrors('');
		if (email.trim() !== '' && password.trim() !== '') {
			remotePost(plugApis().login, JSON.stringify({ email, password }), {
				'Content-Type': 'application/json',
			})
				.then(function (response) {
					if (response.success) {
						sessionStorage.setItem(
							'autoplug-rest',
							response.data.token
						);
						sessionStorage.setItem(
							'autoplug-user',
							JSON.stringify(response.data.user)
						);
						navigate('/admin/dashboard');
					} else {
						setErrors(response.errors.message);
					}
					setIsLoading(false);
				})
				.catch(function () {
					setErrors('Please try again!');
					setIsLoading(false);
				});
		}
	};
	return (
		<div className="mb-16 mt-16 flex h-full w-full items-center !justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
			{/* Sign in section */}
			<form
				onSubmit={handleLogin}
				className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
			>
				<h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
					Sign In
				</h4>
				<p className="mb-9 ml-1 text-base text-gray-600">
					Enter your email and password to sign in!
				</p>
				{/* Email */}
				<InputField
					variant="auth"
					extra="mb-3"
					label="Email*"
					required={true}
					placeholder="mail@suretriggers.com"
					id="email"
					type="text"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				{/* Password */}
				<InputField
					variant="auth"
					extra="mb-3"
					label="Password*"
					required={true}
					placeholder="Min. 8 characters"
					id="password"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>

				<div className="border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400">
					{errors}
				</div>

				<button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
					Sign In
				</button>
			</form>
		</div>
	);
}
