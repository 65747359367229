import React from 'react';
import { MdBarChart } from 'react-icons/md';
import Card from 'components/card';
import { lineChartOptions, barChartOptions } from 'variables/charts';
import LineChart from 'components/charts/LineChart';
import BarChart from 'components/charts/BarChart';

const Chart = (props) => {
	return (
		<Card extra={`!p-[20px] text-center ${props?.className}`}>
			<div className="mb-auto flex items-center justify-between px-6">
				<h2 className="text-lg font-bold text-navy-700 dark:text-white">
					{props?.title}
				</h2>
				<button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
					<MdBarChart className="h-6 w-6" />
				</button>
			</div>

			<div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
				<div className="h-full w-full">
					{props.type === 'bar' ? (
						<BarChart
							chartData={props.series}
							chartOptions={barChartOptions}
						/>
					) : (
						<LineChart
							options={lineChartOptions}
							series={props.series}
						/>
					)}
				</div>
			</div>
		</Card>
	);
};

export default Chart;
