import React, { useMemo } from 'react';
import Card from 'components/card';
import { dateFormat } from 'utils/HelperFunctions';

import {
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from 'react-table';

const DashboardTable = (props) => {
	const { columnsData, tableData } = props;

	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { pageSize: 20, pageIndex: 0, pageCount: -1 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		state,
		pageCount,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
	} = tableInstance;

	return (
		<Card extra={'w-full h-full sm:overflow-auto px-6'}>
			<header className="relative flex items-center justify-between pt-4">
				<div className="text-xl font-bold text-navy-700 dark:text-white">
					{props.title}
				</div>
			</header>

			<div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
				<table
					{...getTableProps()}
					className="w-full"
					variant="simple"
					color="gray-500"
					mb="24px"
				>
					<thead>
						{headerGroups.map((headerGroup, index) => (
							<tr
								{...headerGroup.getHeaderGroupProps()}
								key={index}
							>
								{headerGroup.headers.map((column, index) => (
									<th
										{...column.getHeaderProps(
											column.getSortByToggleProps()
										)}
										className="border-b border-gray-200 pb-[10px] pr-16 text-start dark:!border-navy-700"
										key={index}
									>
										<div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
											{column.render('Header')}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()} key={index}>
									{row.cells.map((cell, index) => {
										let data = '';
										if (cell.column.Header === 'Date') {
											data = (
												<div className="flex items-center gap-2">
													<p className="text-sm font-normal text-navy-700 dark:text-white">
														{dateFormat(
															'M j, Y',
															cell.value
														)}
													</p>
												</div>
											);
										} else {
											data = (
												<div className="flex items-center">
													<p className="text-center text-sm font-bold text-navy-700 dark:text-white">
														{cell.value}
													</p>
												</div>
											);
										}
										return (
											<td
												{...cell.getCellProps()}
												key={index}
												className="pb-[16px] pt-[14px] sm:text-[14px]"
											>
												{data}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>

				<div className="mt-4 flex hidden justify-between">
					<button
						className="rounded bg-blue-500 px-4 py-2 text-white"
						onClick={previousPage}
						disabled={!canPreviousPage}
					>
						Previous
					</button>
					<div>
						Page{' '}
						<strong>
							{state.pageIndex + 1} of {pageCount}
						</strong>
					</div>
					<button
						className="rounded bg-blue-500 px-4 py-2 text-white"
						onClick={nextPage}
						disabled={!canNextPage}
					>
						Next
					</button>
				</div>
			</div>
		</Card>
	);
};

export default DashboardTable;
