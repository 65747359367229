import React, { useEffect, useState } from 'react';
import Dropdown from 'components/dropdown';
import { FiAlignJustify } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowBarUp } from 'react-icons/bs';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import {
	IoMdNotificationsOutline,
	IoMdInformationCircleOutline,
} from 'react-icons/io';
import avatar from 'assets/img/logos/SureTrigger-icon-logo.png';
import { getDbCookie } from 'utils/HelperFunctions';

const Navbar = (props) => {
	const { onOpenSidenav, brandText } = props;
	const [darkmode, setDarkmode] = useState(false);
	const user = JSON.parse(getDbCookie('autoplug-user') ?? '{}');
	const navigate = useNavigate();
	useEffect(() => {
		const savedDarkMode = localStorage.getItem('darkMode');
		if (savedDarkMode) {
			setDarkmode(JSON.parse(savedDarkMode));
		}
	}, []);

	useEffect(() => {
		if (darkmode) {
			document.body.classList.add('dark');
		} else {
			document.body.classList.remove('dark');
		}
		// Save dark mode state to localStorage
		localStorage.setItem('darkMode', JSON.stringify(darkmode));
	}, [darkmode]);

	const toggleDarkMode = () => {
		setDarkmode(!darkmode);
	};

	return (
		<nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#1d1f284d]">
			<div className="ml-[6px]">
				<p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
					<Link
						to="#"
						className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
					>
						{brandText}
					</Link>
				</p>
			</div>

			<div className="relative mt-[3px] flex h-[61px] w-[140px] flex-grow items-center justify-around gap-4 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-darkBodyCard dark:shadow-none md:w-[140px] md:flex-grow-0 md:gap-1 xl:w-[140px] xl:gap-2">
				<span
					className="flex text-xl text-gray-600 cursor-pointer dark:text-white xl:hidden"
					onClick={onOpenSidenav}
					tabIndex={0}
					role="presentation"
				>
					<FiAlignJustify className="w-5 h-5" />
				</span>
				{/* start Notification */}
				<Dropdown
					button={
						<p className="cursor-pointer">
							<IoMdNotificationsOutline className="w-4 h-4 hidden text-gray-600 dark:text-white" />
						</p>
					}
					animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
					children={
						<div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-darkNotification dark:text-white dark:shadow-none sm:w-[460px]">
							<div className="flex items-center justify-between">
								<p className="text-base font-bold text-navy-700 dark:text-white">
									Notification
								</p>
								<p className="text-sm font-bold text-navy-700 dark:text-white">
									Mark all read
								</p>
							</div>

							<button className="flex items-center w-full">
								<div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
									<BsArrowBarUp />
								</div>
								<div className="flex flex-col justify-center w-full h-full px-1 ml-2 text-sm rounded-lg">
									<p className="mb-1 text-base font-bold text-left text-gray-900 dark:text-white">
										New Update: SureTriggers
									</p>
									<p className="text-xs text-left text-gray-900 font-base dark:text-white">
										A new update for your downloaded item is
										available!
									</p>
								</div>
							</button>

							<button className="flex items-center w-full">
								<div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
									<BsArrowBarUp />
								</div>
								<div className="flex flex-col justify-center w-full h-full px-1 ml-2 text-sm rounded-lg">
									<p className="mb-1 text-base font-bold text-left text-gray-900 dark:text-white">
										New Update: SureTriggers
									</p>
									<p className="text-xs text-left text-gray-900 font-base dark:text-white">
										A new update for your downloaded item is
										available!
									</p>
								</div>
							</button>
						</div>
					}
					classNames={
						'py-2 top-4 -left-[230px] md:-left-[440px] w-max'
					}
				/>
				<Dropdown
					button={
						<p className="cursor-pointer">
							<IoMdInformationCircleOutline className="w-4 h-4 text-gray-600 dark:text-white" />
						</p>
					}
					children={
						<div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-darkNotification dark:text-white dark:shadow-none">
							
							<a
								target="blank"
								href="https://app.suretriggers.com"
								className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
							>
								App
							</a>
							<a
								target="blank"
								href="https://app.suretriggers.com/pricing"
								className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
							>
								Pricing
							</a>
						</div>
					}
					classNames={
						'py-2 top-6 -left-[250px] md:-left-[330px] w-max'
					}
					animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
				/>
				<div
					className="text-gray-600 cursor-pointer"
					onClick={toggleDarkMode}
					tabIndex={0}
					role="presentation"
				>
					{darkmode ? (
						<RiSunFill className="w-4 h-4 text-gray-600 dark:text-white" />
					) : (
						<RiMoonFill className="w-4 h-4 text-gray-600 dark:text-white" />
					)}
				</div>
				{/* Profile & Dropdown */}
				<Dropdown
					button={
						<img
							className="w-10 h-10 rounded-full cursor-pointer"
							src={avatar}
							width={100}
							height={100}
							alt="SureTrigger"
						/>
					}
					children={
						<div className="flex h-24 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-darkNotification dark:text-white dark:shadow-none">
							<div className="mt-3 ml-4">
								<div className="flex items-center gap-2">
									<p className="text-sm font-bold text-navy-700 dark:text-white">
										👋 Hey, {user?.display_name}
									</p>
								</div>
							</div>
							<div className="w-full h-px mt-3 bg-gray-200 dark:bg-white/20 " />

							<div className="flex flex-col mt-3 ml-4">
								<a
									href=" "
									className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
									onClick={() => {
										sessionStorage.removeItem(
											'autoplug-rest'
										);
										navigate('/auth/sign-in');
									}}
								>
									Log Out
								</a>
							</div>
						</div>
					}
					classNames={'py-2 top-8 -left-[180px] w-max'}
				/>
			</div>
		</nav>
	);
};

export default Navbar;
