const defaultHeaders = {
	// 'autoplug-session': getDbCookie('autoplug-session'),
	//'Authorization': 'Bearer ' + getDbCookie('autoplug-rest'),
	// 'Content-Type': 'application/json'
};

const remotePost = async (url = '', formData = {}, headers = {}) => {
	const response = await fetch(url, {
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'include', // include, *same-origin, omit
		headers: {
			//'Content-Type': 'application/json',
			// 'Content-Type': 'application/x-www-form-urlencoded',
			...defaultHeaders,
			...headers,
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		body: formData, // body data type must match "Content-Type" header
	});

	return response.json(); // parses JSON response into native JavaScript objects
};

const remoteGet = async (url = '', headers = {}, init = {}) => {
	const response = await fetch(url, {
		...init,
		method: 'GET', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			//'Content-Type': 'application/json',
			// 'Content-Type': 'application/x-www-form-urlencoded',
			...defaultHeaders,
			...headers,
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	});

	return response.json(); // parses JSON response into native JavaScript objects
};

const remoteDelete = async (url = '', headers = {}) => {
	const response = await fetch(url, {
		method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			//'Content-Type': 'application/json',
			// 'Content-Type': 'application/x-www-form-urlencoded',
			...defaultHeaders,
			...headers,
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	});

	return response.json(); // parses JSON response into native JavaScript objects
};

export { remotePost, remoteGet, remoteDelete };
