import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`mx-1 inline-block min-w-[30px] rounded px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-brand-600 hover:text-white focus:z-20 focus:outline-offset-0 active:bg-brand-400 dark:text-white  dark:ring-gray-800 dark:hover:bg-brand-300 dark:active:bg-brand-400 ${
              i === currentPage
                ? "bg-brand-500 text-white"
                : "bg-gray-200 dark:bg-darkBody dark:text-gray-700 hover:dark:text-gray-300"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      const visiblePages = [];

      if (currentPage <= 3) {
        for (let i = 1; i <= 5; i++) {
          visiblePages.push(
            <button
            disabled={i === currentPage}
              key={i}              
              className={`mx-1 inline-block rounded px-2 py-1 ${
                i === currentPage ? "bg-blue-500 text-white" : "bg-gray-200 "
              }`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
        visiblePages.push(
          <li key="right-ellipsis" className="inline-block px-2 py-1 mx-1">
            ...
          </li>
        );
      } else if (currentPage >= totalPages - 2) {
        visiblePages.push(
          <li key="left-ellipsis" className="inline-block px-2 py-1 mx-1">
            ...
          </li>
        );
        for (let i = totalPages - 4; i <= totalPages; i++) {
          visiblePages.push(
            <button
            disabled={i === currentPage}
              key={i}
              className={`mx-1 inline-block rounded px-2 py-1 ${
                i === currentPage ? "bg-blue-500 text-red" : "bg-gray-200"
              }`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
      } else {
        visiblePages.push(
          <li key="left-ellipsis" className="inline-block px-2 py-1 mx-1">
            ...
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <button
              key={i}
              disabled={i === currentPage}
              className={`mx-1 inline-block rounded px-2 py-1 ${
                i === currentPage ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
        visiblePages.push(
          <li key="right-ellipsis" className="inline-block px-2 py-1 mx-1">
            ...
          </li>
        );
      }

      pageNumbers.push(...visiblePages);
    }

    return pageNumbers;
  };

  return (
    <div className="flex items-center">
      
        <button
        disabled={currentPage === 1}
          className={`mx-1 h-[30px] rounded px-2 py-1 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 active:bg-brand-400 dark:text-white  dark:ring-gray-800 dark:hover:bg-brand-300 dark:active:bg-brand-400 ${
            currentPage === 1
              ? "bg-gray-200 disabled:cursor-not-allowed"
              : "bg-gray-200 text-gray-500  dark:bg-darkBody dark:text-gray-700 hover:dark:text-gray-300 hover:bg-brand-600 hover:text-white"
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <svg
            className="w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      
      {renderPageNumbers()}
      
        <button
        disabled={currentPage === totalPages}
          className={`mx-1 h-[30px] rounded px-2 py-1 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 active:bg-brand-400 dark:text-white  dark:ring-gray-800 dark:hover:bg-brand-300 dark:active:bg-brand-400 ${
            currentPage === totalPages
              ? "bg-gray-200 disabled:cursor-not-allowed"
              : "bg-gray-200 text-gray-500  dark:bg-darkBody dark:text-gray-700 hover:dark:text-gray-300 hover:bg-brand-600 hover:text-white"
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <svg
            className="w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      
    </div>
  );
};

export default Pagination;
