/*eslint-disable*/
import React from "react";
export default function Footer() {
  return (
    <div className="z-[5] mx-auto flex w-full max-w-screen-sm flex-col items-center justify-between px-[20px] pb-4 lg:mb-6 lg:max-w-[100%] lg:flex-row xl:mb-2 xl:w-[1310px] xl:pb-6">
      <p className="mb-6 text-center text-sm text-gray-600 md:text-base lg:mb-0">
        ©{1900 + new Date().getYear()} SureTriggers. All Rights Reserved.
      </p>
      <ul className="flex flex-wrap items-center sm:flex-nowrap gap-10">
      <li>
						<a
							target="blank"
							href="mailto:support@suretriggers.com"
							className="text-base font-medium text-gray-600 hover:text-gray-600"
						>
							Support
						</a>
					</li>
					<li>
						<a
							target="blank"
							href="https://app.suretriggers.com"
							className="text-base font-medium text-gray-600 hover:text-gray-600"
						>
							App
						</a>
					</li>
      </ul>
    </div>
  );
}
